import React from 'react';
import S from 'styled-components';
import {Link, useLocation} from 'react-router-dom';

const ProjectSkillLabel = (props) => {
    let path = useLocation();

    return(
        <>
            {path.pathname === '/' 
                ? <StyledLink data-skill-id={props.technology.id} to={{pathname:`/projects`, state:{techId: props.technology.id}}}><Label >{props.technology.Technology}</Label></StyledLink>
                : <Label data-skill-id={props.technology.id}>{props.technology.Technology}</Label>
            }
        </>
    );
}
export default ProjectSkillLabel;

const Label = S.p`
    width: fit-content;
    margin-right: 10px;
    margin-top: 10px;
    font-size: calc(.3em + .3vw);
    padding: .2em 1em;
    background-color: #fff;
    color: #000;
    border-radius: 20px;
    font-weight: 600;
    min-width: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all ease-in-out 120ms;

    @media only screen and (max-width: 600px) {
        font-size: calc(.8em + 1.5vw);
	}
`;
const StyledLink = S(Link)`
    &:hover {
            ${Label} {
                background-color: #410285;
                color: #fff;
            }
`;