import React, { useState, useEffect } from 'react';
import S from 'styled-components';
import axios from 'axios';
import {withRouter} from 'react-router-dom';

// Local Dev built components
import Project from './Project';
import Skills from './Skills';
import Loader from './Loader/IsLoading';


const ProjectPage = (props) => {
    if(props.location.state === undefined) props.location.state = {techId: 0};
    const [projects, setProjects] = useState([]);
    const [skillId, setSkillId] = useState(0 || props.location.state.techId);
    const [isLoading, setIsLoading] = useState(true);

    const handleClick =  (event) => {
        setSkillId(event.target.getAttribute("data-skill-id"));
   }

    // Get projects and project images on inital render.
        useEffect(() => {
            if(props.match.path === '/projects') {
            axios.get(`https://dylansieren-portfolio.herokuapp.com/projects`)
                .then(async response => {
                    // If the default state is active, just grab all the projects.
                    if (skillId === 0) {
                        await setProjects(response.data);
                        await setIsLoading(false);
                        // Else, return our filtered projects, based on the skill selected
                    } else {
                        await setProjects(response.data.filter(project =>  project.technologies.some(technology => technology.id === skillId)))
                        await setIsLoading(false);
                    }

                })
                .catch(err => {
                    console.log(err);
                })
            } else {
                axios.get(`https://dylansieren-portfolio.herokuapp.com/projects?Featured=true`)
                .then(async response => {
                        await setProjects(response.data);
                        await setIsLoading(false);
                })
                .catch(err => {
                    console.log(err);
                })
            }
        }, [skillId])



    return (
        <>
            {props.match.path === '/projects' ? <Skills skillId={skillId} handleClick={handleClick} /> : null }
            <ProjectSection>
                {props.match.path === '/' ? <SectionTitle>Featured Projects</SectionTitle> : null }
                {isLoading === true 
                ? <Loader />
                : projects && projects.map((project, index) => {
                    return <Project key={index++} project={project} index={index}/>
                })}
            </ProjectSection>
        </>
    );
}
export default withRouter(ProjectPage);

const ProjectSection = S.section`
    display: flex;
    flex-flow: row wrap;
    width: 80%;
    margin: 0 auto;
    margin-bottom: 2em;
    margin-top: 100px;
`;
const SectionTitle = S.h2`
    font-size: 50px;
    color: silver;
    width: 100%;
    text-align: center;
    margin-bottom: 25px;

    @media only screen and (max-width: 600px) {
    font-size: 30px;
    }
`;

