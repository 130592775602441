import React, {useState, useEffect} from 'react';
import { useLocation } from "react-router-dom";
import S from 'styled-components';
import axios from 'axios';




const Skills = (props) => {
    const { pathname } = useLocation();    
    const [skills, setSkills] = useState([]);

    useEffect( () => {
        axios.get(`https://dylansieren-portfolio.herokuapp.com/technologies`)
        .then(async response => {
            await setSkills(response.data.sort(function(a,b) {
                return a.id - b.id
            }));
        })
        .catch(err => { 
            console.log(err);
        })
    }, [])

    return(
        <SkillsSection>
            {pathname === '/blog' ? <SectionTitle>Featured Blogs</SectionTitle> : <SectionTitle>My Projects</SectionTitle>}
            <SkillList>
                <Skill data-skill-id="0"  onClick={props.handleClick}>- All -</Skill>
                {skills && skills.map( (skill, index) => {
                    return <Skill data-skill-id={skill.id} key={index++} onClick={props.handleClick}>{skill.Technology}</Skill>
                })}
            </SkillList>
        </SkillsSection>
    );
}

export default Skills;

const SkillsSection = S.section`
    width: 80%;
    margin: 80px auto;
    padding-top: 100px;
`;

const SectionTitle = S.h2`
    font-size: 50px;
    color: silver;
    width: 100%;
    text-align: center;
    margin-bottom: 25px;

    @media only screen and (max-width: 600px) {
       font-size: 30px;
	}
`;
const SkillList = S.ul`
    width: 65%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row wrap;
    margin: 0 auto;

    @media only screen and (max-width: 600px) {
        width: 100%;
	}
`;

const Skill = S.li`
    display: flex;
    align-items: center;
    list-style: none;
    min-width: 100px;
    justify-content: center;
    margin: 15px;

    font-size: 20px;
    color: #fff;
    background-color: #410285;
    border-radius: 25px;
    padding: 10px 20px;
    transition: all ease-in-out 300ms;
    &:hover {
        cursor: pointer;
        transform: scale(1.2);
    }

    @media only screen and (max-width: 600px) {
        font-size: 16px;
	}
`;