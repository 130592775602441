import React from 'react';
import { BrowserRouter as Router, Switch, Route} from "react-router-dom";
import './index.css';
// Local Developer Made Components
import DesktopMenu from './Menus/DesktopMenu';
import HomePage from './Components/HomePage';
import BlogPosts from './Components/BlogPostsPage/BlogPosts';
import IndividualBlog from './Components/BlogPostsPage/IndividualBlog';
import Footer from './Components/Footer';
import ProjectPage from './Components/ProjectPage';
import IndividualProject from './Components/IndividualProjectPage';
import AboutPage from './Components/AboutMePage';
import ResourcePage from './Components/ResourcePage/ResourcePage';
import ScrollToTop from './Components/ScrollToTop';


function App() {

  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        <DesktopMenu />
        <Switch>
          <Route exact path="/" component={HomePage}/>
          <Route exact path="/blog" component={BlogPosts}/>
          <Route exact path="/projects" component={ProjectPage}/>
          <Route exact path="/blog/:id" component={IndividualBlog}/>
          <Route exact path="/project/:id" component={IndividualProject}/>
          <Route exact path="/about" component={AboutPage}/>
          <Route exact path="/notes" component={ResourcePage}/>
        </Switch>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
