import S from 'styled-components';

const MainTitle = S.h6`
    font-size: 50px;
    color: #000;
    width: 90%;
    margin: 0 auto;
    margin-bottom: 10px;

    @media only screen and (max-width: 600px) {
        font-size: 30px;
	}
`;

export { 
    MainTitle, 
};