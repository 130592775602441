import React, {useState, useEffect} from 'react';
import S from 'styled-components';
import axios from 'axios';
import marked from 'marked';
// import './blogposts.css';


const IndividualProject  = (props) => {
    const [project, setProject] = useState([]);
    const [description, setDescription] = useState('');
    const [challenges, setChallenges] = useState('');
    const [goals, setGoals] = useState('');
    const [solution, setSolution] = useState('');
    
    useEffect( () => {
        axios.get(`https://dylansieren-portfolio.herokuapp.com/projects/${props.match.params.id}`)
        .then(async response => {
            await setProject(response.data);
            await setDescription(marked(response.data.Description));
            await setChallenges(marked(response.data.Challenges));
            await setGoals(marked(response.data.Goals));
            await setSolution(marked(response.data.Solution));
            console.log(response.data);
        })
        .catch( error => {
            console.log(error);
        })
    }, [])
    return(
        <PageContainer>
            <Card>
                <Content>
                    <ProjectLink target="_blank" href={`${project.Website_URL}`}><Title>{project.Title}</Title></ProjectLink>
                    <FeaturedImage src={project.image && project.image[0].url}></FeaturedImage>
                    <SubTitle>Background</SubTitle>
                    <div className="blog-body" dangerouslySetInnerHTML={{__html: description}}></div>
                    <SubTitle>Challenges</SubTitle>
                    <div className="blog-body" dangerouslySetInnerHTML={{__html: challenges}}></div>
                    <SubTitle>Goals</SubTitle>
                    <div className="blog-body" dangerouslySetInnerHTML={{__html: goals}}></div>
                    <SubTitle>Resolve</SubTitle>
                    <div className="blog-body" dangerouslySetInnerHTML={{__html: solution}}></div>
                </Content>
            </Card>
        </PageContainer>
    );
}

export default IndividualProject;

const PageContainer = S.div`
    width: 70%;
    margin: 0 auto;

    @media only screen and (max-width: 600px) {
        width: 90%;
	}
`;
const Card = S.div`
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    padding: 200px 20px 100px 20px;
    box-sizing: border-box;
`;
const Content = S.section`
    width: 80%;
    display: flex;
    flex-flow: row wrap;
    margin: 0 auto;
`;
const Title = S.h6`
    font-size: 50px;
    color: rgb(84, 171, 246);
    width: 100%;
    margin: 0 auto;
    margin-bottom: 10px;
    transition: all ease-in-out 200ms;

    @media only screen and (max-width: 600px) {
        font-size: 30px;
	}
`;
const SubTitle = S.h3 `
    width: 100%;
    font-size: 34px;
    color: rgb(84, 171, 246);
`;

const FeaturedImage = S.img`
    width: 100%;
    height: auto;
    margin: 0 auto;
    margin-bottom: 50px;
`;

const ProjectLink = S.a`
    font-family: 'Montserrat', sans-serif;

    &: hover ${Title} {
        color: #410285;
    }
`;