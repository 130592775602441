import React from 'react';
import S from 'styled-components';

const ProjectTitle = (props) => {

    return(
        <Title primary={props.primary}>{props.title}</Title>
    );
}
export default ProjectTitle;

const Title = S.h2`
    font-size: ${props => props.primary ? 'calc(1em + 1.2vw)': 'calc(.5em + .8vw)'};
    color: ${props => props.primary ? '#54ABF6': '#a8a8a8'};
    font-family: 'Montserrat', sans-serif;
    width: 100%;

    transition: all ease in 200ms;
    &:hover  {
        color: ${props => props.primary ? '#410285': '#a8a8a8'};
    }
    @media only screen and (max-width: 600px) {
        font-size: ${props => props.primary ? 'calc(3em + 1.2vw)': 'calc(1.5em + .8vw)'};
	}
`;