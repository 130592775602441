import React from 'react';
import S from 'styled-components';
import {Link} from 'react-router-dom';
import marked from 'marked';
import './blogcard.css';

const BlogPosts = (props) => {
    const trimmedTestimonialText = (
        props.blog.Body.length > 100 ?
            `${props.blog.Body.substring(0, 100)}...` :
            props.blog.Body
    )
    const rawMarkUp = marked(trimmedTestimonialText);

    return(
            <Card>
                <ContentContainer >
                    <ImageContainer>
                        <FeaturedImage src={props.blog.Assets[0] && props.blog.Assets[0].url}></FeaturedImage>
                    </ImageContainer>
                    <ContentContainer primary={true}>
                        <StyledTitle to={`/blog/${props.blog.id}`}><Title>{props.blog.Title}</Title></StyledTitle>
                        <div id="blogcard_markdown" dangerouslySetInnerHTML={{__html: rawMarkUp}}></div>
                        <DateContainer><DateLabel>{props.blog.Date}</DateLabel></DateContainer>
                        <TagContainer>
                            {props.blog.technologies && props.blog.technologies.map(tag => {
                                return <Tag>{tag.Technology}</Tag>;
                            })}
                        </TagContainer>
                    </ContentContainer>
                </ContentContainer>

            </Card>

    );
}

export default BlogPosts;

const Card = S.div`
    width: 30%;
    min-width: 350px;
    display: flex;
    flex-flow: row wrap;
    margin-bottom: 20px;
    border: 1px solid #565656;
    box-sizing: border-box;
    background-color: #eaeaea;
    border-radius: 5px;

    @media only screen and (max-width: 1300px) {
        width: 30%;
        min-width: 400px;
	}

    @media only screen and (max-width: 980px) {
        width: 45%;
        min-width: 300px;
	}
    @media only screen and (max-width: 600px) {
        width: 100%;
	}
`;
const StyledTitle = S(Link)`
    text-decoration: none;
    margin-bottom: 10px;
`;
const Title = S.h6`
    font-size: 26px;
    width: 100%;
    color: #0f0f0f;
    margin: 0;

    @media only screen and (max-width: 600px) {
        font-size: 30px;
	}
`;
const ContentContainer = S.div`
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
    width: 100%;
    padding: ${props => props.primary ? '20px' : '0'};


    @media only screen and (max-width: 600px) {
        width: 100%;
	}
`;
const ImageContainer = S.div`
    display: flex;
    width: 100%;
    flex-direction: column;

    @media only screen and (max-width: 600px) {
        width: 100%;
        margin-bottom: 30px;
	}
`;
const FeaturedImage = S.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;
const DateContainer = S.div`
    width: fit-content;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    margin-top: 20px;
`;
const DateLabel = S.span`
    font-size: 12px;
    font-weight: 900;
    font-family: 'Open Sans', sans-serif;
    color: #2e2e2e;
`;

const TagContainer = S.ul`
    display: flex;
    align-items: center;
    width: 100%;
`;
const Tag = S.li`
    font-size: 14px;
    margin-right: 10px;
    color: #2e2e2e;
    text-decoration: underline;
    font-style: italic;
`;