import React from 'react';
import { useSpring, animated} from 'react-spring';
import S from 'styled-components';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithubAlt } from '@fortawesome/free-brands-svg-icons';
import {ReactComponent as Logo} from '../Images/browser_icon.svg';
import {Link} from 'react-router-dom';


// Local Dev built components
import ProjectImage from './ProjectImage';
import ProjectTitle from './ProjectTitle';
import ProjectSummary from './ProjectSummary';
import ProjectSkillLabel from './ProjectSkillLabel';

// React Spring Animations
const transCalc = (x, y) => [-(y - window.innerHeight / 2) / 1200, (x - window.innerWidth / 2) / 1200, 1.01];
const transTilt = (x, y, s) => `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`;
let technologyStack;

const Project = (props) => {

    const projectPosition = props.index + 1;
    let isEvenFlag = false;
    if(projectPosition % 2 === 0) {
        isEvenFlag = true;
    }
if(props.project && props.project.technologies !== undefined) {
/* This needs to be a double nested for loop since we only use filteredTechnologYstack array  */
    technologyStack = props.project.technologies.map((tech) => {
        return tech;
    })

/*---------------------------------------------------------------------------------------------*/
}
const [props2, set2] = useSpring( () => ({ xys: [0, 0, 1], config: { mass: 5, tension: 350, friction: 40} }));

    return(
        <ProjectContainer 
            isEven={isEvenFlag}
            onMouseMove={({ clientX: x, clientY: y }) => set2({ xys: transCalc(x, y) })}
            style={{ transform: props2.xys.interpolate(transTilt) }}
            onMouseLeave={() => set2({ xys: [0, 0, 1] })}
        >
            <ProjectTextContainer isEven={isEvenFlag}>
                <StyledLink to={`/project/${props.project.id}`}><ProjectTitle primary={true} title={props.project.Title}/></StyledLink>
                <ProjectSummary summary={props.project.Summary}/>
                {props.project.technologies !== undefined && props.project.technologies.length ?
                    <ProjectTechContainer>
                        <ProjectTitle primary={false} title={'Technologies Used'}/>
                        {technologyStack.map( (technology) => {
                            return <ProjectSkillLabel technology={technology}/>
                        })}
                    </ProjectTechContainer>
                : null}
                <LinkContainer>
                        <ProjectLink target="_blank" href={`${props.project.Github}`}><FontAwesomeIcon icon={faGithubAlt}/></ProjectLink>
                        <ProjectLink target="_blank" href={`${props.project.Website_URL}`}><StyledLogo></StyledLogo></ProjectLink>
                </LinkContainer>
            </ProjectTextContainer>
            <ProjectImageContainer>
                <ProjectImage imageUrl={props.project.image[0].url}/>
            </ProjectImageContainer>
        </ProjectContainer>
    );
}
export default Project;

const ProjectContainer = S(animated.div)`
    display: flex;
    justify-content: space-between;
    flex-flow: ${props => props.isEven ? 'row-reverse wrap' : 'row wrap'};
    width: 100%;
    margin: 2em auto;

    @media only screen and (max-width: 600px) {
        flex-flow: column-reverse;
        width: 100%;
        margin: 5em auto;
	}
`;
const ProjectTextContainer = S.div`
    display: flex;
    flex-flow: row wrap;
    width: 50%;
    box-sizing: border-box;
    padding: ${props => props.isEven ? '0 0 0 20px' : '0 20px 0 0'};

    @media only screen and (max-width: 600px) {
        width: 100%;
        padding-left: 0;
        padding-top: 10px;
	}
`;
const ProjectTechContainer = S.div`
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    margin-top: 1em;

`;
const LinkContainer = S.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 1em;

    border-top: 1px solid silver;
    padding-top: 10px;
`;
const ProjectLink = S.a`
    font-size: calc(1rem + .3vw);;
    color: #fff;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all ease-in-out 500ms;
    &:hover {
        transform: rotate(360deg) scale(1.2);
    }
    @media only screen and (max-width: 600px) {
        font-size: 40px;
	}

`;
const ProjectImageContainer = S.div`
    width: 49%;
    z-index: 100;

    @media only screen and (max-width: 600px) {
        width: 100%;
	}
`;
const StyledLink = S(Link)`
  
`;
const StyledLogo = S(Logo)`
    
`;
