import React from 'react';
import S from 'styled-components';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

function SearchButton(props) {

    return (
        <>
         {props.isActive 
         ?  <ButtonContainer  type="submit" isActive={props.isActive} onClick={props.handleSubmit} animateButton={props.animateButton}>
                <StyledIcon icon={faSearch}/>
            </ButtonContainer>

         :  <ButtonContainer onClick={props.handleClick} animateButton={props.animateButton}>
                <StyledIcon icon={faSearch}/>
            </ButtonContainer>}

        </>
    )
}

export default SearchButton;

const StyledIcon = S(FontAwesomeIcon)`
    color: #fff;
    opacity: 1;
    font-size: 26px;
    transition: all ease-in-out 300ms;
`;

const ButtonContainer = S.button`
    width: 59px;
    border: none;
    z-index: 100;
    height: 59px;
    background-color: #54ABF6;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: fixed;
    transform: ${props => props.isActive ? 'translateX(-65px)' : 'translateX(0)'};
    left: 80%;
    top: 90%;
    transition: all ease-in-out 300ms;
    transition-delay: ${props => props.isActive ? '400ms' : '300ms'};

    &:hover {
        cursor: pointer;
        ${StyledIcon} {
            color: #410285;
        }
    }
`;