import React, {useState, useEffect} from 'react';
import S from 'styled-components';
import axios from 'axios';
import marked from 'marked';
import './blogposts.css';


const IndividualBlog  = (props) => {
    const [blog, setBlog] = useState([]);
    const [markedText, setMarkedText] = useState();
    
    useEffect( () => {
        axios.get(`https://dylansieren-portfolio.herokuapp.com/blogs/${props.match.params.id}`)
        .then(async response => {
            await setBlog(response.data);
            await setMarkedText(marked(response.data.Body));
        })
        .catch( error => {
            console.log(error);
        })
    }, [])
    return(
        <PageContainer>
            <Card>
                <Title>{blog.Title}</Title>
                <Date>{blog.Date}</Date>
                <FeaturedImage src={blog.Assets && blog.Assets[0].url}></FeaturedImage>
                <div className="blog-body" dangerouslySetInnerHTML={{__html: markedText}}></div>
            </Card>
        </PageContainer>
    );
}

export default IndividualBlog;

const PageContainer = S.div`
    width: 80%;
    margin: 0 auto;

    @media only screen and (max-width: 600px) {
        width: 90%;
	}
`;
const Card = S.div`
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    padding: 200px 20px 100px 20px;
    box-sizing: border-box;
`;

const Title = S.h6`
    font-size: 50px;
    color: rgb(84, 171, 246);
    width: 90%;
    margin: 0 auto;
    margin-bottom: 10px;

    @media only screen and (max-width: 600px) {
        font-size: 30px;
	}
`;
const Date = S.p`
    font-size: 16px;
    padding-bottom: 20px;
    width: 90%;
    margin: 0 auto;
`;
const FeaturedImage = S.img`
    width: 90%;
    height: auto;
    margin: 0 auto;
    margin-bottom: 50px;
`;
