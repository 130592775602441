import React from 'react';
import S from 'styled-components';
import background_image from '../Images/about_me_bg.jpg';

const AboutMePage = () => {
    return(
        <AboutMePageWrapper>
            <ContentWrapper>
                <SectionTitle>
                    About Me
                </SectionTitle>
                <Content>
                    <ImageContainer>
                        <StyledImg src={background_image}/>
                    </ImageContainer>
                    <TextContentWrapper>
                        <TextTitle>
                            Hi There! My name is Dylan Sieren.
                        </TextTitle>
                        <Body>I am a Web Developer at <a href="https://herkimer.media">Herkimer Media</a> where I focus primarily
                        on Front-End tasks.
                        </Body>
                        <Body>
                            My whole coding journey started pretty late. I decided I wanted to pursue the web development space in late 2017 by enrolling
                            at a two-year college and a computer science bootcamp(Lambda School). During my free time you can find me building 
                            side projects, learning new technologies, solving technical problems, teaching myself UI Design, or playing video games.
                        </Body>
                        <Body>
                            My favorite thing to do in my spare time though is trying to build these minimalistic, modern websites that bring ease of use to my users.
                        </Body>
                        <Body>
                            If you want to chat more, don't hesitate to reach out. I am only a couple clicks and keystrokes away. <a href="mailto:dylan.sieren01@gmail.com">dylan.sieren01@gmail.com</a>
                        </Body>
                    </TextContentWrapper>
                </Content>
            </ContentWrapper>
        </AboutMePageWrapper>
    );
}

export default AboutMePage;

const AboutMePageWrapper = S.section`
    width: 100%;
`;
const ContentWrapper = S.div`
    height: 100%;
    width: 75%;
    padding-top: 150px;
    margin: 0 auto;
`;
const SectionTitle = S.h2`
    font-size: 50px;
    color: silver;
    width: 100%;
    text-align: center;
    margin-bottom: 50px;

    @media only screen and (max-width: 600px) {
       font-size: 30px;
	}
`;
const Content = S.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
`;
const ImageContainer = S.div`
    width: 30%;
    position: relative;

    &:before {
        content: '';
        position: absolute;
        background-color: silver;
        width: 100%;
        height: 100%;
        top: -10px;
        left: -10px;
        z-index: 0;
    }
`;

const StyledImg = S.img`
    width: 100%;
    object-fit: cover;
    position: relative;
    z-index: 10;
`;
const TextContentWrapper = S.div`
    width: 65%;
    box-sizing: border-box;
    padding:  0 20px 20px 20px;
`;
const TextTitle = S.h3`
    font-size: 30px;
    margin-top: 0;
    color: #54ABF6;
`;
const Body = S.p`
    width: 100%;
    color: #fff;
    font-size: 22px;
    margin-top: 25px;
`;
