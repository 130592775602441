import React, {useState} from 'react';
import S, {keyframes} from 'styled-components';
import SearchButton from './SearchButton';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus} from "@fortawesome/free-solid-svg-icons";

function SearchContainer(props) {
    const [isActive, setIsActive] = useState(false);
    const [animateButton, setAnimateButton] = useState(false);
    const [displaySetting, setDisplaySetting] = useState('none');

    const stretch_out = keyframes`
        0% { width: 0%; opacity: 0; visibility: hidden; display: flex;}
        100% { width: 100%; opacity: 1; visibility: visible; display: flex;}
    `;
    const stretch_out_input = keyframes`
        0% { width: 0%; opacity: 0; visibility: hidden; display: flex;}
        100% { width: 65%; opacity: 1; visibility: visible; display: flex;}
    `;
    const stretch_in = keyframes`
        0% {width: 100%;  visibility: visible; display: flex;  }
        100% {width: 0%;  visibility: hidden; display: none;}
    `;
    const stretch_in_input = keyframes`
        0% { width: 65%;  visibility: visible; display: flex;}
        100% { width: 0%;  visibility: hidden; display: none;}
    `;

    const [animation, setAnimation] = useState(stretch_out);
    const [animationInput, setAnimationInput] = useState(stretch_out_input);

    const handleClick = (event) => {
        event.preventDefault();
        if(animateButton === false) {
            setAnimateButton(true);
            setTimeout(
                () => {
                    setIsActive(true);
                    setDisplaySetting('flex');
                    setAnimation(stretch_out);
                    setAnimationInput(stretch_out_input);

                }, 
                200
            );
        } 
    }

    const handleClose = () => {
        if(isActive === false) {
            setAnimateButton(true);
            setTimeout(
                () => {
                    setIsActive(true);
                }, 
                200
            );

        } else if(isActive === true) {
            setAnimateButton(false);
            setIsActive(false);
            setTimeout(
                () => {
                    setDisplaySetting('none');
                }, 
                600
            );
            setAnimation(stretch_in);
            setAnimationInput(stretch_in_input);
        }
    }
    
    return (
        <Parent>
            <Container isActive={isActive} animation={animation} displaySetting={displaySetting}>
                <Input onChange={props.handleInput} isActive={isActive} animationInput={animationInput} type='textfield' name="search" value={props.userInput} placeholder='Search project skills'/>
            </Container>
            <SearchButton handleClick={handleClick} handleSubmit={props.handleSubmit} animateButton={animateButton}  isActive={isActive}/>
            {isActive 
            ?   <ExitButtonContainer onClick={handleClose}>
                    <StyledIcon icon={faPlus}/>
                </ExitButtonContainer>
            : null}

        </Parent>
    )
}

export default SearchContainer;
const Parent = S.form`
    width: 80%;
    position: fixed;
    top: 90%;
    z-index: 100;
    left: 0;
    right: 0;
    margin: 0 auto;
`;

const Container = S.div`
    display: ${props => props.displaySetting};
    float: right;
    align-items: center;
    background-color: #54ABF6;
    border-radius: 50%;
    margin: 0 auto;
    justify-content: space-between;
    padding: 10px;
    border-radius: 25px;
    box-sizing: border-box;
    animation-name: ${ props => props.animation};
    animation-duration: 1s;
    animation-fill-mode: forwards;
`;

const Input = S.input`
    border-radius: 25px;
    outline: none;
    background-color: #fff;
    font-size: 20px;
    padding: 8px;
    border: none;
    padding-left: 15px;
    visibility: ${ props=> props.isActive ? 'visible' : 'hidden' };
    opacity: ${props => props.isActive ? '1' : '0' };
    box-sizing: border-box;
    animation-name: ${ props => props.animationInput};
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
`;
const StyledIcon = S(FontAwesomeIcon)`
    color: #fff;
    opacity: 1;
    font-size: 26px;
    transform: rotate(45deg);
    transition: all ease-in-out 300ms;
`;

const ExitButtonContainer = S.div`
    width: 59px;
    height: 59px;
    background-color: #54ABF6;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: fixed;
    left: 80%;
    top: 90%;
    &:hover {
        cursor: pointer;
        ${StyledIcon} {
            color: #410285;
        }
    }
`;