import React from 'react';
import S from 'styled-components';
import HeroSection from './HeroSection.jsx';
import ProjectPage from './ProjectPage.jsx';
import BlogPostPage from './BlogPostsPage/BlogPosts';
import Contact from './Contact';

const HomePage = () => {
    return(
        <HomePageWrapper>
            <HeroSection />
            <ProjectPage />
            <BlogPostPage />
            <Contact />
        </HomePageWrapper>
    );
}

export default HomePage;

const HomePageWrapper = S.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: row wrap;
`;