import React from 'react';
import S from 'styled-components';
import { useSpring, animated } from 'react-spring';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithubAlt, faLinkedin, faCodepen } from "@fortawesome/free-brands-svg-icons";
import Curve from '../Images/HeroWave.svg';


const HeroSection = (props) => {

    // React Spring Circle Animations
    const aboutProps = useSpring({
        opacity: 1,
        marginLeft: 0,
        from: {
            opacity: 0,
        },
        delay: 150,
        config: { mass: 10, tension: 450, friction: 100}
    })
    const hookProps = useSpring({
        opacity: 1,
        from: {
            opacity: 0,
        },
        delay: 600,
        config:{ duration: 1000}
    })
    const showProjectsProps = useSpring({
        opacity: 1,
        from: {
            opacity: 0,
        },
        delay: 800,
        config:{ duration: 1000}
    })

    return (
        <Layer>
            <ContentWrapper>
                <TextContentContainer>
                    <DeveloperObjectText style={aboutProps}>Dylan Sieren | Front-End Engineer | UI Designer</DeveloperObjectText>                   
                    <DeveloperObjectText primary={true} style={hookProps}>Creating Websites Users Can Easily Interface With.</DeveloperObjectText>
                    <MoreWorkContainer style={showProjectsProps}>
                        <MoreWorkLink href="/contact">Reach Out</MoreWorkLink>
                    </MoreWorkContainer>
                </TextContentContainer>
                <GitHubContainer>
                    <Strike></Strike>
                    <ExternalLink href="https://github.com/ilyris"><FontAwesomeIcon icon={faGithubAlt} /></ExternalLink>
                    <ExternalLink href="https://www.linkedin.com/in/dylan-sieren/"><FontAwesomeIcon icon={faLinkedin} /></ExternalLink>
                    <ExternalLink href="https://codepen.io/dsieren/"><FontAwesomeIcon icon={faCodepen} /></ExternalLink>
                </GitHubContainer>
            </ContentWrapper>
        </Layer>
    );
}

export default HeroSection;

const Layer = S.div`
    display: flex;
    align-items: center;
    height: 100vh;
    flex-flow: row wrap;
    position: relative;
    z-index: 20;
    margin: -100px;
    width: 100%;
    overflow: hidden;
    background-color: #54ABF6;

    &:before {
        content: '';
        position: absolute;
        left: -.5%;
        bottom: -15px;
        width: 101%;
        height: 300px;
        background: url(${Curve});         
        background-repeat: no-repeat;
        background-size: cover;
    }

    @media only screen and (max-width: 600px) {
        &:before {
            left: -4.5%;
            bottom: -10px;
            width: 106%;
            height: 200px;
        }
	  }
`;


const ContentWrapper = S(animated.div)`
    display: flex;
    flex-flow: row wrap;
    z-index: 11;
    position: relative;
    align-content: center;
    height: 75%;
    width: 100%;
    margin: 0 auto;
`;
const TextContentContainer = S.div`
    display: flex;
    flex-flow: row wrap;
    width: 80%;
    margin: 0 auto;
    justify-content: center;
    z-index: 10;
`;
const DeveloperObjectText = S(animated.h2)`
    font-size: ${props => props.primary ? 'calc(1.5rem + 1.5vw);' : 'calc(.3rem + .5vw);'};
    font-weight: 900;
    font-family: ${props => props.primary ? 'inherit' : 'Open Sans, sans-serif'};
    color: ${props => props.primary ? '#fff' : '#0f0f0f'};
    width: 100%;
    text-shadow: 3px 4px 5px #73737347;
    line-height: ${props => props.primary ? '85px' : 'unset'};
    margin:  ${props => props.primary ? '.5em 0' : '0'};

    @media only screen and (max-width: 600px) {
        font-size: ${props => props.primary ? '46px' : '16px'};
        line-height: ${props => props.primary ? '50px' : 'unset'};
	  }
`;
const MoreWorkContainer = S(animated.div)`
    width: 100%;
    display: flex;
`;
const MoreWorkLink = S.a`
    color: #fff;
    font-size: calc(.5rem + .2vw);
    border: 1.5px solid #0f0f0f;
    background-color: #0f0f0f;
    font-weight: 900;
    padding: .2em .7em .3em .7em;
    transition: all ease-in-out 120ms;
    &:hover {
        background-color: #410285;
        color: #ffffff;
        border-color: #410285;
    }

    @media only screen and (max-width: 600px) {
        font-size: 20px;
	  }
`;

const GitHubContainer = S.div`
    display: flex;
    width: 80%;
    margin: 0 auto;
    align-items: center;

    @media only screen and (max-width: 600px) {
        transform: translate3d(0, 20px, 0);
	}
`;

const Strike = S.div`
    height: 3px;
    width: 40%;
    margin-right: 40px;
    background-color: #fff;
`;

const ExternalLink = S.a`
    color: #0f0f0f;
    font-size: calc(1rem + .3vw);
    margin-right: 25px;
    transition: all ease-in-out 300ms;

    &:hover {
        color: #410285;
    }

    @media only screen and (max-width: 600px) {
        font-size: 30px;
	  }
`;
