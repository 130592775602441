import React from 'react';
import S from 'styled-components';

// Create a project component that includes a:
    // Title component
    // Desc. component
    // Image component
// The project component will have the project data, then it will feed it into the lower components.

const ProjectText = (props) => {

    return(
    <TextContainer>
        <Text>{props.summary}</Text>
    </TextContainer>
    );
}
export default ProjectText;

const TextContainer = S.div`
    display: flex;
`;
const Text = S.p`
    font-size: calc(.3em + .5vw);
    color: #fff;

    @media only screen and (max-width: 600px) {
        font-size: calc(2.2em + .5vw);
	}
`;