import React from 'react';
import S from 'styled-components';

// Author Imports
import {MainTitle} from '../ReuseableComponents/Titles.jsx'
const ResourcePage = () => {
    return(
        <PageContainer>
            <Card>
            <MainTitle>My Notes</MainTitle>
            </Card>
        </PageContainer>
    );
}
const PageContainer = S.div`
    width: 80%;
    margin: 0 auto;

    @media only screen and (max-width: 600px) {
        width: 90%;
	}
`;
const Card = S.div`
    width: 100%;
    display: flex;
    background-color: #fff;
    flex-flow: row wrap;
    padding: 200px 20px 100px 20px;
    box-sizing: border-box;
`;
export default ResourcePage;