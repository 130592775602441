import React, { useState, useEffect } from 'react';
import axios from 'axios';
import S from 'styled-components';
import { withRouter } from 'react-router-dom';

// Developer made components
import BlogCard from './BlogCard';
import Loader from '../Loader/IsLoading';
import SearchContainer from '../ReuseableComponents/SearchContainer';

const BlogPosts = (props) => {
    const [blogs, setBlogs] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [userInput, setuserInput] = useState('');
    const [searchInput, setSearchInput] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        setSearchInput(userInput);
        setuserInput('');
        window.scrollTo(0, 0);
    }
    const handleInput = (event) => {
        setuserInput(event.target.value);
    }
    useEffect(() => {
        // Maybe cache our data, then check the data so we're not making multiple queries to the database.
        if (props.match.path === '/blog') {
            axios.get(`https://dylansieren-portfolio.herokuapp.com/blogs?_sort=id:ASC`)
                .then(async response => {
                    if (searchInput === '') {
                        await setBlogs(response.data);
                        await setIsLoading(false);
                        // Else, return our filtered projects, based on the skill selected
                    } else if(searchInput !== '' ) {
                        await setBlogs(response.data.filter(blog => blog.technologies.some(technology => {
                            return technology.Technology.toLocaleLowerCase() === searchInput.toLocaleLowerCase()
                        })))
                        await setIsLoading(false);
                    }
                })
                .catch(err => {
                    console.log(err);
                })

        } else {
            axios.get(`https://dylansieren-portfolio.herokuapp.com/blogs?_sort=id:ASC&Featured=true`)
                .then(async response => {
                    await setBlogs(response.data);
                    await setIsLoading(false);

                })
                .catch(err => {
                    console.log(err);
                })
        }
    }, [searchInput])

    return (
        <>
            {props.match.path === '/blog' && window.innerWidth <= 500 ? <SearchContainer handleInput={handleInput} handleSubmit={handleSubmit} userInput={userInput}/> : null}
            <PostsContainer>
                {props.match.path === '/' ? <SectionTitle>Blog Posts</SectionTitle> : null}
                {isLoading === true
                    ? <Loader />
                    : <CardContainer>
                        {blogs && blogs.map((blog, index) => {
                            return <BlogCard key={index++} blog={blog} />
                        })}
                    </CardContainer>
                }
            </PostsContainer>
        </>
    );
}

export default withRouter(BlogPosts);

const PostsContainer = S.section`
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    margin-bottom: 2em;
`;

const CardContainer = S.div`
    width: 80%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin: 2em auto;
    @media only screen and (max-width: 600px) {
        width: 80%;
	}
`;
const SectionTitle = S.h2`
    font-size: 50px;
    color: silver;
    width: 100%;
    text-align: center;
    margin-bottom: 25px;

    @media only screen and (max-width: 600px) {
    font-size: 30px;
    }
`;