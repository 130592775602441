import React from 'react';
import S from 'styled-components';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faGithubAlt, faCodepen, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import {ReactComponent as Logo} from '../Images/dylan_sieren_2021_portfolio_logo.svg';



const Footer = () => {
    return (
        <FooterContainer>
            <FooterMenu topMenu={true}>
                <FooterLi><FooterAnchors href="index.html">Home</FooterAnchors></FooterLi>
                <FooterLi><FooterAnchors href="work.html">Work</FooterAnchors></FooterLi>
                <FooterLi><FooterAnchors href="index.html#contactSection">Contact</FooterAnchors></FooterLi>
                <FooterLi><FooterAnchors href="about.html">About</FooterAnchors></FooterLi>
            </FooterMenu>
            <FooterMenu>
                <FooterLi>
                    <FooterAnchors href="https://github.com/ilyris" target="_blank">
                        <IconContainer ><SocialFooterIcons icon={faGithubAlt} /></IconContainer>
                    </FooterAnchors>
                </FooterLi>
                <FooterLi>
                    <FooterAnchors href="https://www.linkedin.com/in/dylan-sieren/" target="_blank">
                        <IconContainer ><SocialFooterIcons icon={faLinkedin} /></IconContainer>
                    </FooterAnchors>
                </FooterLi>
                <FooterLi>
                    <FooterAnchors href="https://codepen.io/dsieren/" target="_blank">
                        <IconContainer ><SocialFooterIcons icon={faCodepen} /></IconContainer>
                    </FooterAnchors>
                </FooterLi>
                <FooterLi>
                    <FooterAnchors href="mailto:dylan.sieren01@gmail.com">
                        <IconContainer ><SocialFooterIcons icon={faEnvelope} /></IconContainer>
                    </FooterAnchors>
                </FooterLi>
            </FooterMenu>
            <LogoContainer>
                <LogoLink href="#">
                    <StyledLogo></StyledLogo>
                </LogoLink>
            </LogoContainer>
            <CopyRightText>© 2020 Dylan Sieren</CopyRightText>
        </FooterContainer>
    );
}

        export default Footer;


const FooterContainer = S.footer`
    width: 100%;
    overflow: hidden;
    background-color: #54abf6;
    display: flex;
    padding: 150px 0 0 0;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    align-items: center;
    padding-bottom: 100px;
    position: relative;


    &:before {
        content: '';
        position: absolute;
        top: -10px;
        height: 310px;
        background: url(/static/media/HeroWave.f0afa7d5.svg);
        background-repeat: no-repeat;
        background-size: cover;
        transform: rotate(180deg);
        width: 110%;
        overflow: hidden;
    }

    @media only screen and (max-width: 600px) {
        padding-bottom: 50px;
	}
`;
const FooterMenu = S.ul`
    width: 50%;
    display: flex;
    flex-direction: ${props => props.topMenu ? 'column' : 'row'} ;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    z-index: 100;
`;

const FooterLi = S.li`
    list-style: none;
    transition: all ease 300ms;
    font-size: 0px;
    margin-right: 10px;
`;

const FooterAnchors = S.a`
    text-decoration: none;
    color: #fff;
    font-size: 18px;

    &:hover {
        text-decoration: underline;
    }
`;

const SocialFooterIcons = S(FontAwesomeIcon)`
    text-decoration: underline;
    color: #d8d7d7;
    font-size: 30px;
    z-index: 100;
`;

const IconContainer = S.div`
    text-align: center;
    display: flex;
    justify-content: center;
    padding: 10px;
    border-radius: 30px;
    transition: all ease 300ms;

    &:hover {
        background-color: #fff;
    }
    &:hover ${SocialFooterIcons}{
        color: #410285;;
    }
`;
const LogoContainer = S.div`
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px 0;
    justify-content: center;
`;
const LogoLink = S.a`
    display: flex;
    align-items: center;
`;
const StyledLogo = S(Logo)`
    width: 100px;
    height: auto;
`;
const CopyRightText = S.p`
    font-size: 12px;
    color: #fff;

`;