import React from 'react';
import S from 'styled-components';

// Don't know how to pull images from cloudinary.
// Maybe make an array of all my uploaded images.
// then loop through the array and pass them down as props.

const ProjectImage = (props) => {
    return (
        <>
            {props.imageUrl && <Image src={`${props.imageUrl}`} />}
        </>
    );
}
export default ProjectImage;

const Image = S.img`
    width: 100%;
    z-index: 100;
    height: 100%;
`;