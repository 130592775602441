import React from 'react';
import S from 'styled-components';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

const Contact = () => {
    return (
        <ContactContainer id="contact">
            <Heading>Have something interesting you want to talk about?</Heading>
            <SubHeading>Whether that be a an idea you want to see come to life, want to hire me for work, or just want to chat about all things web development; don't be afraid to drop me a message</SubHeading>
            <MoreWorkLink href="mailto:dylan.sieren01@gmail.com">Email Me<StyledIcon icon={faArrowRight}/></MoreWorkLink>
        </ContactContainer>
    );
}

export default Contact;

const ContactContainer = S.section`
    display: flex;
    align-items: center;
    width: 80%;
    margin: 150px auto;
    flex-flow: row wrap;

    @media only screen and (max-width: 600px) {
        margin: 50px auto 100px auto;
        width: 90%;
	}
`;

const Heading = S.h2`
    font-size: calc(1rem + .5vw);
    color: #54abf6;
    text-align: center;
    margin-top: 20px;
    width: 100%;

    @media only screen and (max-width: 600px) {
        font-size: 30px;
	}
`;
const SubHeading = S.p`
    font-size: calc(.3em + .5vw);
    color: #fff;
    text-align: center;
    margin-top: 20px;
    width: 100%;

    @media only screen and (max-width: 600px) {
        font-size: calc(2em + .5vw);
	}
`;

const StyledIcon = S(FontAwesomeIcon)`
    transition: all ease-in-out 300ms;
    transform: translate3d(0, 0px, 0px);
    color: #fff;
    opacity: 0;
    font-size: 0;
`;
const MoreWorkLink = S.a`
    color: #000;
    font-size: calc(.5rem + .2vw);
    border: 1.5px solid #0f0f0f;
    background-color: #fff;
    font-weight: 900;
    padding: .5em 2em;
    transition: all ease-in-out 120ms;
    width: auto;
    margin: 80px auto 0 auto;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 100;
    justifty-content: cener;
        &:hover {
        background-color: #410285;
        color: #ffffff;
        border-color: #410285;
            ${StyledIcon} {
                transform: translate3d(5px, 2px, 0px);
                margin-left: 5px;
                opacity: 1;
                font-size: 22px;
            }
    }

    @media only screen and (max-width: 600px) {
        font-size: calc(2.5em + .5vw);
	}
`;
